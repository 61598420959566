import { makeStyles } from '@material-ui/styles'
import renderBloks from '@renderBloks'
import { MarkdownHandler, Modal, TextSm } from '@system'
import React, { useState } from 'react'
import SbEditable from 'storyblok-react'

const useStyles = makeStyles((theme) => ({
  speakerCardRoot: ({ isWebinarSeriesVariant }) => ({
    alignItems: isWebinarSeriesVariant ? 'flex-start' : 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: isWebinarSeriesVariant ? '16px' : '24px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'row',
      gap: '24px',
    },
  }),
  speakerCardImage: ({ isWebinarSeriesVariant }) => ({
    width: isWebinarSeriesVariant ? '80px' : '150px',
  }),
  speakerCardDetail: ({ isWebinarSeriesVariant }) => ({
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    textAlign: isWebinarSeriesVariant ? 'left' : 'center',
    '& a': {
      display: 'inline-block',
    },
    [theme.breakpoints.up('sm')]: {
      flex: '1',
      textAlign: 'left',
    },
  }),
  speakerCardName: ({ isWebinarSeriesVariant }) => ({
    color: isWebinarSeriesVariant
      ? theme.palette.text.iris
      : theme.palette.primary.main,
    cursor: isWebinarSeriesVariant ? 'pointer' : 'arrow',
    fontSize: '20px',
    fontWeight: theme.typography.fontWeightBold,
  }),
  speakerCardInfo: {
    fontSize: '16px',
    lineHeight: '1.35',
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
    },
  },
  speakerCardModal: {
    '& .MuiDialogContent-root': {
      backgroundColor: '#fff',
      maxWidth: '440px',
      padding: '24px',
      width: 'calc(100vw - 64px)',
    },
  },
  speakerCardModalImage: {
    marginBottom: '12px',
    width: '100px',
  },
  speakerCardModalDetail: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  speakerCardModalName: {
    fontSize: '20px',
    fontWeight: theme.typography.fontWeightBold,
  },
}))

const SpeakerCard = ({ blok, variant }) => {
  const { company, image, info, jobTitle, name } = blok
  const isWebinarSeriesVariant = variant === 'webinarSeries'
  const classes = useStyles({ isWebinarSeriesVariant })
  const [isModalOpen, setIsModalOpen] = useState(false)

  let jobInfo = []
  if (!!jobTitle) jobInfo.push(jobTitle)
  if (!!company) jobInfo.push(company)

  return (
    <SbEditable content={blok}>
      <div className={classes.speakerCardRoot}>
        {image?.length > 0 && (
          <div className={classes.speakerCardImage}>{renderBloks(image)}</div>
        )}
        <div className={classes.speakerCardDetail}>
          {!!name && (
            <TextSm
              className={classes.speakerCardName}
              onClick={() => {
                if (isWebinarSeriesVariant) setIsModalOpen(true)
              }}
            >
              {name}
            </TextSm>
          )}
          <div>
            {!isWebinarSeriesVariant && !!info && (
              <MarkdownHandler className={classes.speakerCardInfo}>
                {info}
              </MarkdownHandler>
            )}
            {jobInfo.length > 0 && (
              <TextSm className={classes.speakerCardInfo}>
                {jobInfo.join(', ')}
              </TextSm>
            )}
          </div>
        </div>
      </div>
      {/* Modal for speakers referenced in WebinarSeriesEvent component */}
      {isWebinarSeriesVariant && (
        <Modal
          handleClose={() => setIsModalOpen(false)}
          open={isModalOpen}
          className={classes.speakerCardModal}
        >
          {image?.length > 0 && (
            <div className={classes.speakerCardModalImage}>
              {renderBloks(image)}
            </div>
          )}
          <div className={classes.speakerCardModalDetail}>
            <div>
              {!!name && (
                <TextSm className={classes.speakerCardModalName}>{name}</TextSm>
              )}
              {jobInfo.length > 0 && (
                <TextSm className={classes.speakerCardInfo}>
                  {jobInfo.join(', ')}
                </TextSm>
              )}
            </div>
            {!!info && (
              <MarkdownHandler className={classes.speakerCardInfo}>
                {info}
              </MarkdownHandler>
            )}
          </div>
        </Modal>
      )}
    </SbEditable>
  )
}

export default SpeakerCard
